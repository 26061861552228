import { lazy } from 'react';

import DashboardLayout from 'layouts/DashboardLayout';
import Permission from 'constants/permission';
import { TDashboardRoutes } from './Dashboard.types';

const Home = lazy(() => import('./Home'));
const AdminUserList = lazy(() => import('./AdminUserList'));
const TransactionList = lazy(() => import('./TransactionList'));
const PaymentList = lazy(() => import('./PaymentList'));
const PayoutList = lazy(() => import('./PayoutList'));
const PayoutInfo = lazy(() => import('./PayoutInfo'));
const VisaReconciliation = lazy(() => import('./VisaReconciliation'));
const ComplianceRequestList = lazy(() => import('./ComplianceRequestList'));
const BillingPackageList = lazy(() => import('./BillingPackageList'));
const MerchantFeeList = lazy(() => import('./MerchantFeeList'));
const CashbackCampaignList = lazy(() => import('./CashbackCampaignList'));
const CashbackCampaignDetail = lazy(() => import('./CashbackCampaignDetail'));
const CardList = lazy(() => import('./CardList'));
const AuditLogList = lazy(() => import('./AuditLogList'));
const OnboardingList = lazy(() => import('./OnboardingList'));

const routes: TDashboardRoutes = {
  home: {
    path: '/',
    exact: true,
    menuItemId: 'menu.home',
    titleId: 'page.title.home',
    description: 'Home Page',
    component: Home,
    isPrivate: true,
    layout: DashboardLayout,
  },
  adminUser: {
    path: '/admin-user',
    exact: true,
    menuItemId: 'menu.adminUserList',
    titleId: 'page.title.adminUserList',
    description: 'Admin Users Page',
    component: AdminUserList,
    isPrivate: true,
    layout: DashboardLayout,
    expectedPermission: Permission.READ_ADMIN_USER,
  },
  transactions: {
    path: '/transactions',
    exact: true,
    menuItemId: 'menu.transactionList',
    titleId: 'page.title.transactionList',
    description: 'Transactions Page',
    component: TransactionList,
    isPrivate: true,
    layout: DashboardLayout,
    expectedPermission: Permission.READ_MERCHANT_TRANSACTION,
  },
  payments: {
    path: '/payments',
    exact: true,
    menuItemId: 'menu.paymentList',
    titleId: 'page.title.paymentList',
    description: 'Payments Page',
    component: PaymentList,
    isPrivate: true,
    layout: DashboardLayout,
    expectedPermission: Permission.READ_MERCHANT_PAYMENT,
  },
  payoutInfo: {
    path: '/payouts/:payoutId',
    exact: true,
    menuItemId: 'menu.payoutList',
    titleId: 'page.title.payoutList',
    description: 'Payouts Page',
    component: PayoutInfo,
    isPrivate: true,
    layout: DashboardLayout,
    expectedPermission: Permission.READ_MERCHANT_PAYOUT,
  },
  visaReconciliation: {
    path: '/visa-settlements',
    exact: true,
    menuItemId: 'menu.visaReconciliation',
    titleId: 'page.title.visaReconciliation',
    description: 'Visa Reconciliation Page',
    component: VisaReconciliation,
    isPrivate: true,
    layout: DashboardLayout,
    expectedPermission: Permission.READ_VISA_RECONCILIATION,
  },
  payouts: {
    path: '/payouts',
    exact: true,
    menuItemId: 'menu.payoutList',
    titleId: 'page.title.payoutList',
    description: 'Payouts Page',
    component: PayoutList,
    isPrivate: true,
    layout: DashboardLayout,
    expectedPermission: Permission.READ_MERCHANT_PAYOUT,
  },
  complianceRequests: {
    path: '/compliance-requests',
    exact: true,
    menuItemId: 'menu.complianceRequestList',
    titleId: 'page.title.complianceRequestList',
    description: 'Compliance Requests Page',
    component: ComplianceRequestList,
    isPrivate: true,
    layout: DashboardLayout,
    expectedPermission: Permission.READ_COMPLIANCE_REQUEST,
  },
  billingPackages: {
    path: '/billing-packages',
    exact: true,
    menuItemId: 'menu.billingPackageList',
    titleId: 'page.title.billingPackageList',
    description: 'Billing Packages Page',
    component: BillingPackageList,
    isPrivate: true,
    layout: DashboardLayout,
    expectedPermission: Permission.READ_BILLING_PACKAGE,
  },
  merchantFees: {
    path: '/merchants/fees',
    exact: true,
    menuItemId: 'menu.merchantFeeList',
    titleId: 'page.title.merchantFeeList',
    description: 'Merchant fees',
    component: MerchantFeeList,
    isPrivate: true,
    layout: DashboardLayout,
    expectedPermission: Permission.READ_MERCHANT_BILLING_DEBT,
  },
  cashbackCampaigns: {
    path: '/cashback-campaigns',
    exact: true,
    menuItemId: 'menu.cashbackCampaignList',
    titleId: 'page.title.cashbackCampaignList',
    description: 'Cashback campaigns',
    component: CashbackCampaignList,
    isPrivate: true,
    layout: DashboardLayout,
    expectedPermission: Permission.READ_CASHBACK_CAMPAIGN,
  },
  cashbackCampaignDetail: {
    path: '/cashback-campaigns/:cashbackCampaignId',
    exact: true,
    menuItemId: 'menu.cashbackCampaignList',
    titleId: 'page.title.cashbackCampaignDetail',
    description: 'Cashback campaign detail',
    component: CashbackCampaignDetail,
    isPrivate: true,
    layout: DashboardLayout,
    expectedPermission: Permission.READ_CASHBACK_CAMPAIGN,
  },
  cards: {
    path: '/cards',
    exact: true,
    menuItemId: 'menu.cards',
    titleId: 'page.title.merchantCards',
    description: 'Merchant cards',
    component: CardList,
    isPrivate: true,
    layout: DashboardLayout,
    expectedPermission: Permission.READ_MERCHANT,
  },
  auditLogs: {
    path: '/audit-logs',
    exact: true,
    menuItemId: 'menu.auditLogs',
    titleId: 'page.title.auditLogs',
    description: 'Audit logs',
    component: AuditLogList,
    isPrivate: true,
    layout: DashboardLayout,
    expectedPermission: Permission.READ_ADMIN_AUDIT_LOG,
  },
  onboarding: {
    path: '/onboarding',
    exact: true,
    menuItemId: 'menu.onboarding',
    titleId: 'page.title.onboarding',
    description: 'Onboarding review',
    component: OnboardingList,
    isPrivate: true,
    layout: DashboardLayout,
    expectedPermission: Permission.READ_MERCHANT,
  },
};

export default routes;
